<template>
  <b-row>
    <b-col cols="12">
      <InvoicesDetail />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import InvoicesDetail from "./TableInvoiceDetail.vue";

export default {
  components: {
    BRow,
    BCol,

    InvoicesDetail,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
