<template>
  <b-card-code title="" no-body>
    <!-- list data calon angggota-->
    <template #modal-title>Detail Data </template>
    <!-- Invoice Description: Total -->
    <b-form @submit.prevent>
      <table style="width: 100%">
        <b-table-simple
          hover
          caption-top
          responsive
          class="rounded-bottom mb-0"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th colspan="7" class="text-right">
                #{{ detailInvoices.id }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-th rowspan="30">
                Data Invoice
              </b-th>
              <b-th class="text-right">
                Anggota :
              </b-th>
              <b-td
                ><a
                  :href="`/anggota/${detailInvoices.orang_id}`"
                  target="_blank"
                  >{{ detailInvoices.orang_id }}</a
                ></b-td
              >
            </b-tr>
            <b-tr>
              <b-th class="text-right">
                Invoice Name :
              </b-th>
              <b-td> {{ detailInvoices.invoice_name }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right">
                Invoice Jumlah :
              </b-th>
              <b-td> {{ detailInvoices.invoice_jumlah }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right">
                Invoice Status :
              </b-th>
              <b-td> {{ detailInvoices.invoice_status }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right">
                Dibuat :
              </b-th>
              <b-td> {{ detailInvoices.created_at }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right">
                Invoice File :
              </b-th>
              <b-td v-if="detailInvoices.invoice_file != null">
                <a
                  v-if="!detailInvoices.invoice_file.includes('https')"
                  :href="
                    'http://www.idijakpus.or.id/uploads/reg/invoice_file/' +
                      detailInvoices.id +
                      '/' +
                      detailInvoices.invoice_file
                  "
                  alt="pdf"
                  target="_blank"
                  >Tampilkan File Pendidikan</a
                >
                <a
                  v-else
                  :href="detailInvoices.invoice_file"
                  alt="pdf"
                  target="_blank"
                  >Lihat File Invoices</a
                >
              </b-td>
              <b-td v-else>{{ "file tidak ada" }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right">
                Status :
              </b-th>
              <b-td
                ><validation-provider
                  #default="{ errors }"
                  name="invoice_status"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <v-select
                      id="status_tipe"
                      v-model="approveInvoice.invoice_status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusInvoice"
                      placeholder="Invoice Status.."
                    />
                  </b-input-group> </validation-provider
              ></b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> </b-th>
              <b-td>
                <div class="demo-inline-spacing">
                  <b-button
                    variant="gradient-primary"
                    @click="verifikasiInvoices"
                    type="submit"
                  >
                    <feather-icon icon="CheckCircleIcon" class="mr-50" />
                    <span class="align-middle">Verifikasi</span>
                  </b-button>
                </div></b-td
              >
            </b-tr>
          </b-tbody>
          <br />
          <br />
          <br />
          <br />
          <b-tfoot>
            <b-tr>
              <b-td colspan="7" variant="secondary" class="text-right"> </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </table>
    </b-form>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BButton,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BFormDatepicker,
  BTfoot,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BModal,
  BFormFile,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { quillEditor } from "vue-quill-editor";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BFormFile,
    BCardBody,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BBadge,
    vSelect,
    BFormDatepicker,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BCard,
    BCardText,
    BCardTitle,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BModal,
    BFormTextarea,
    quillEditor,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ["multi", "single", "range"],
      search: null,
      detailInvoices: {
        orang_id: {
          orang_nama_lengkap: "",
        },
      },
      approveInvoice: {
        invoice_status: "",
      },
      sendemail: {
        subject: "",
        message: "",
      },
      selectMode: "multi",
      tanggals: [], //map tanggal krip
      option: [], // store tanggal with option select

      solidColor: [{ bg: "danger", title: "Danger card title" }],

      snowOptions: {
        theme: "snow",
      },
      statusInvoice: ["sudah-dibayar", "belum-dibayar"],
    };
  },

  computed: {},
  methods: {
    refreshPage() {
      if (this.currentPage == 1) {
        this.getDetailInvoices();
      } else {
        this.currentPage = 1;
      }
    },

    async verifikasiInvoices() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        this.$swal({
          title: "Apakah kamu yakin?",
          text:
            "Data invoice ini diverifikasi, pastikan invoice yang dibayar sesuai nominal",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, verifikasi!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            let dataInvoice = {
              invoice_status: this.approveInvoice.invoice_status,
            };
            if (result.value) {
              return API.invoice.approveInvoice(
                this.$route.params.id_invoices,
                dataInvoice
              );
            }
            return false;
          })
          .then((result) => {
            if (result) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil Verifikasi Invoices",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
          })
          .catch((error) => {
            this.handleMsgErrors(error, "terjadi kesalahan silahkan coba lagi");
          })
          .finally(() => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
          });
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async getDetailInvoices() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const { data } = await API.invoice.detail(
          this.$route.params.id_invoices //fetch data invoice by id(sesuai dengan id invoice)
        );
        this.detailInvoices = data; //simpan detail data invoice in property

        this.handleMsgSuccess("Berhasil Menampilkan Detail Invoices");
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },

  mounted() {
    this.getDetailInvoices();
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
